@import 'react-big-calendar/lib/sass/styles';

.calendar-container {
  height: 1000px;
}

.rbc-month-view {
  border: unset;
  border-bottom: 1px solid var(--bs-gray-300);

  .rbc-header {
    border: none;
    text-align: right;
    padding: 0.5rem 0;
  }

  .rbc-today {
    background-color: unset;
    border-top: 4px solid var(--bs-blue-400);
  }

  .rbc-month-row {
    border-top: 1px solid var(--bs-gray-300);
    border-right: 1px solid var(--bs-gray-300);
    border-left: 1px solid var(--bs-gray-300);

    .rbc-date-cell {
      padding: 3px 7px;
    }

    .rbc-row-segment {
      padding: 2px 7px;
    }

    .rbc-show-more {
      width: 100%;
      color: var(--bs-gray-800);
    }
  }
}

.rbc-event,
.rbc-event.rbc-selected {
  background-color: unset;
  padding: unset;
  color: unset;
  border-radius: unset;
}

.rbc-event:focus {
  outline: unset;
}

.calendar-compact .rbc-event {
  height: 22px;
  min-height: 22px;
}

.calendar-line .rbc-event {
  height: 46px;
  min-height: 46px;
}

.rbc-overlay {
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .rbc-overlay-header {
    padding: 11px;
    margin-bottom: 10px;
  }

  .rbc-event {
    margin-bottom: 4px;
  }
}
