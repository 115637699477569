.error-container {
  height: calc(100vh - 40px - 1.5rem - 1.5rem);

  h1 {
    color: var(--bs-primary);
    font-size: 10rem;
  }

  p {
    font-size: 1.5rem;
  }

  .btn {
    font-size: 1.5rem;
  }
}
