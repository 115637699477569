.rbc-toolbar {
  .rbc-toolbar-label {
    text-align: left;
    font-weight: 800;
    font-size: 2em;

    .rbc-toolbar-year {
      font-weight: 500;
      color: var(--bs-gray-500);
    }
  }

  button {
    font-weight: 700;
    line-height: unset;
    color: var(--bs-gray-800);
    border-color: var(--bs-gray-300) !important;

    svg {
      font-size: 2rem;
    }
  }

  .rbc-btn-prev svg {
    margin-left: -8px;
  }

  .rbc-btn-next svg {
    margin-right: -8px;
  }

  button:hover {
    background-color: var(--bs-gray-300) !important;
  }

  button:active {
    box-shadow: unset;
    background-color: var(--bs-gray-300);
  }

  button:focus {
    color: unset;
    background-color: unset;
    border-color: unset;
  }
}
