@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

$primary: #bac8d4;
$primary-dark: #214a6d;
$light: #e7ebee;
$red-100: #ffe5ea;
$red-400: #fa466a;
$orange-100: #feeadb;
$orange-400: #ec6009;
$yellow-100: #fff6d0;
$yellow-400: #b67d00;
$green-100: #dafbeb;
$green-400: #00c569;
$teal-100: #e5f9fb;
$teal-400: #0aaec1;
$blue-100: #ddf4ff;
$blue-400: #0aa6ef;
$purple-100: #e4e5fb;
$purple-400: #4a52e5;

$custom-colors: (
  primary: $primary,
  primary-dark: $primary-dark,
  light: $light,
  red-100: $red-100,
  red-400: $red-400,
  orange-100: $orange-100,
  orange-400: $orange-400,
  yellow-100: $yellow-100,
  yellow-400: $yellow-400,
  green-100: $green-100,
  green-400: $green-400,
  teal-100: $teal-100,
  teal-400: $teal-400,
  blue-100: $blue-100,
  blue-400: $blue-400,
  purple-100: $purple-100,
  purple-400: $purple-400,
);

$theme-colors: map-merge($theme-colors, $custom-colors);

$font-weight-base: 500;
$headings-font-weight: 800;

@import '~bootstrap/scss/bootstrap';
