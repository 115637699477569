@import 'theme';

.App {
  text-align: left;
}

/********** Custom Bootstrap Variants **********/
.alert {
  border: unset;
  border-radius: unset;
  border-left: 5px solid var(--bs-alert-border-color);
}

.alert-light {
  --bs-alert-color: var(--bs-gray-700);
  --bs-alert-bg: var(--bs-light);
  --bs-alert-border-color: var(--bs-gray-600);
  --bs-alert-link-color: var(--bs-gray-900);
}

.bg-light {
  color: var(--bs-gray-600);
  background-color: var(--bs-light);
}

.bg-red {
  color: var(--bs-red-400);
  background-color: var(--bs-red-100);
}

.bg-orange {
  color: var(--bs-orange-400);
  background-color: var(--bs-orange-100);
}

.bg-yellow {
  color: var(--bs-yellow-400);
  background-color: var(--bs-yellow-100);
}

.bg-green {
  color: var(--bs-green-400);
  background-color: var(--bs-green-100);
}

.bg-blue {
  color: var(--bs-blue-400);
  background-color: var(--bs-blue-100);
}

.bg-purple {
  color: var(--bs-purple-400);
  background-color: var(--bs-purple-100);
}

.btn-primary {
  --bs-btn-color: var(--bs-primary-dark);
  --bs-btn-hover-color: var(--bs-primary-dark);
  --bs-btn-active-color: var(--bs-primary-dark);
  --bs-btn-disabled-color: var(--bs-primary-dark);
}

.btn-outline-light {
  --bs-btn-color: var(--bs-gray-500);
  --bs-btn-border-color: var(--bs-gray-500);
  --bs-btn-hover-color: var(--bs-dark);
  --bs-btn-hover-bg: var(--bs-light);
  --bs-btn-hover-border-color: var(--bs-light);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: var(--bs-gray-700);
  --bs-btn-active-bg: var(--bs-light);
  --bs-btn-active-border-color: var(--bs-light);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-light);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-light);
  --bs-gradient: none;
}

.btn-outline-red {
  --bs-btn-color: var(--bs-red-400);
  --bs-btn-border-color: var(--bs-red-400);
  --bs-btn-hover-color: var(--bs-red-400);
  --bs-btn-hover-bg: var(--bs-red-100);
  --bs-btn-hover-border-color: var(--bs-red-100);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: var(--bs-red-400);
  --bs-btn-active-bg: var(--bs-red-100);
  --bs-btn-active-border-color: var(--bs-red-100);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-red-100);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-red-100);
  --bs-gradient: none;
}

.btn-outline-orange {
  --bs-btn-color: var(--bs-orange-400);
  --bs-btn-border-color: var(--bs-orange-400);
  --bs-btn-hover-color: var(--bs-orange-400);
  --bs-btn-hover-bg: var(--bs-orange-100);
  --bs-btn-hover-border-color: var(--bs-orange-100);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: var(--bs-orange-400);
  --bs-btn-active-bg: var(--bs-orange-100);
  --bs-btn-active-border-color: var(--bs-orange-100);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-orange-100);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-orange-100);
  --bs-gradient: none;
}

.btn-outline-yellow {
  --bs-btn-color: var(--bs-yellow-400);
  --bs-btn-border-color: var(--bs-yellow-400);
  --bs-btn-hover-color: var(--bs-yellow-400);
  --bs-btn-hover-bg: var(--bs-yellow-100);
  --bs-btn-hover-border-color: var(--bs-yellow-100);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: var(--bs-yellow-400);
  --bs-btn-active-bg: var(--bs-yellow-100);
  --bs-btn-active-border-color: var(--bs-yellow-100);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-yellow-100);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-yellow-100);
  --bs-gradient: none;
}

.btn-outline-green {
  --bs-btn-color: var(--bs-green-400);
  --bs-btn-border-color: var(--bs-green-400);
  --bs-btn-hover-color: var(--bs-green-400);
  --bs-btn-hover-bg: var(--bs-green-100);
  --bs-btn-hover-border-color: var(--bs-green-100);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: var(--bs-green-400);
  --bs-btn-active-bg: var(--bs-green-100);
  --bs-btn-active-border-color: var(--bs-green-100);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-green-100);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-green-100);
  --bs-gradient: none;
}

.btn-outline-blue {
  --bs-btn-color: var(--bs-blue-400);
  --bs-btn-border-color: var(--bs-blue-400);
  --bs-btn-hover-color: var(--bs-blue-400);
  --bs-btn-hover-bg: var(--bs-blue-100);
  --bs-btn-hover-border-color: var(--bs-blue-100);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: var(--bs-blue-400);
  --bs-btn-active-bg: var(--bs-blue-100);
  --bs-btn-active-border-color: var(--bs-blue-100);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-blue-100);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-blue-100);
  --bs-gradient: none;
}

.btn-outline-purple {
  --bs-btn-color: var(--bs-purple-400);
  --bs-btn-border-color: var(--bs-purple-400);
  --bs-btn-hover-color: var(--bs-purple-400);
  --bs-btn-hover-bg: var(--bs-purple-100);
  --bs-btn-hover-border-color: var(--bs-purple-100);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: var(--bs-purple-400);
  --bs-btn-active-bg: var(--bs-purple-100);
  --bs-btn-active-border-color: var(--bs-purple-100);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--bs-purple-100);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--bs-purple-100);
  --bs-gradient: none;
}
