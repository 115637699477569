.event-details {
  p,
  a {
    margin: 0;
    color: var(--bs-gray-800);
  }

  label {
    color: var(--bs-gray-600);
    font-weight: 700;
    font-size: 0.85rem;
    text-transform: uppercase;
  }

  .event-details-header {
    .event-pretitle {
      color: var(--bs-gray-600);
      font-size: 0.85rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    .event-subtitle {
      svg {
        color: var(--bs-gray-600);
        font-size: 1.25rem;
      }
    }

    .event-fee {
      font-size: 2.25rem;
    }
  }

  .nav-link {
    color: var(--bs-gray-600);
    font-weight: 700;
    font-size: 0.85rem;
    text-transform: uppercase;
  }

  .nav-underline .nav-link:hover {
    color: var(--bs-gray-700);
  }
}
