.rbc-event-content {
  font-size: 0.75rem;
  font-weight: 700;

  .rbc-event-compact {
    padding: 2px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .rbc-event-compact.disabled {
    filter: brightness(0.65);
  }
}
