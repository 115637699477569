.rbc-event-content {
  font-weight: 800;
  font-size: 0.75rem;

  .rbc-event-item {
    padding: 5px 8px;

    .rbc-event-title {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .rbc-event-subtitle {
      font-weight: 600;
      color: var(--bs-gray-600);
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.rbc-event-item.disabled {
  filter: brightness(0.65);
}
